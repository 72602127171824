@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Palanquin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Palanquin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Poppins:wght@200&display=swap);
*{



}
body {
  background-color: #202020;
}


body{
  overflow-x: hidden;

}
body::-webkit-scrollbar {
  display: none;
}
.App {
  overflow-x:none;
  display: flex;
  flex-direction: column;
  text-align: center;
  color:#F7F9F9;
  overflow-y: hidden;
}

.App__resume{
  margin-top: 310px;
}

.App__header{
  overflow-x:none;
  z-index: 2;
  font-family: 'Poppins', sans-serif;
  position: fixed;
  /* left:500px; */
  background-color: #F7F9F9;
  /* background-color: black; */
  bottom: 1025px;
  margin-top: 0px;
  left: 0px;
  right:0px;
top:0px;
width:100%;
}
.App__header.active{
  z-index: 2;
   box-shadow: 0px 6px 18px -0px #363020;
   background-color: #F7F9F9;
   border-radius: 10px;
}
.App__bg img{
  position: relative;
width: 100%;
top: 0px;
height: 80px;

  
}
#progress{
  position: fixed;
  z-index: 10;
  background: black;
  width: 100%;
  top: 0px;
  left: 0;
  /* height: 10px; */
}

#progress__bar{
  /* transition:width 0.15s linear; */
  background: linear-gradient(to left, red, violet);
  /* height: 100%; */
  /* width: 0%; */
  transform-origin: top left;
  opacity: 0;
  transform: scale(0,0)
}

.App h1{
  
}

.App p {
  margin: 0px;
  margin-bottom: 10px;
}

.contact__component{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
/* 605,838 */

.App button{
  /* display: flex; */
  /* flex-direction: row; */
  border-radius: 0%;
  background-color: #F7F9F9;
  width: 50px;
  height: 50px;
  margin: 5px;
  border: none;
  outline:none;
}

.buttons{
position: fixed;
display: flex;
flex-direction: row;
justify-items: center;
align-content: center;
margin-left:1105px;
}

.links{
  /* position: fixed; */
width: 30px;
height: 25px;

}

.resume{
width: 35px !important;
height: 25px !important;
}
.App__rest{
  
}

body{
    overflow-x:hidden
  }
  body::-webkit-scrollbar {
    display: none;
  }
.contact__container{
    position: absolute;
    left: 0px;
    right: 0px;
    /* background-color:#fdf8e1;
    opacity: 0.9;  */
    transition: transform 500ms ease-in;

}

.contact__container.active{
    background-color:#fdf8e1;
    opacity: 0.8; 
    transform: scale(1.05)
}

.contact{
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;  
   left: 0px;
   right: 0px;
   margin-left: 0px;
   margin-right: 0px;
   margin-bottom: 0px;
}

.contact__container h1 {
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;  
    font-family: 'Poiret One', cursive;
    font-size: 200%;
}




.contact__name  {
    margin: 30px;
    /* opacity: 3; */
    display: flex;
    text-align: left;
    width: 56.2vh;
    height: 20px;
    border-radius: 0px;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
}


.contact__email  {
    font-family: 'Poppins', sans-serif;

    margin: 30px;
    display: flex;
    width: 56.2vh;
    height: 20px;
    text-align: left;
    border-radius: 0px;
    flex-direction: column;
}


.contact__message  {
    font-family: 'Poppins', sans-serif;
    display: flex;
    margin: 30px;
    text-align: left;
    border-radius: 0px;
    flex-direction: column;
}

.contact__name input, textarea{
    font-weight: 900;
    font-size: 18px;
    height: 30px;
    background-color: #272727;
    outline:none;
    color:#363020 ;
    -webkit-text-fill-color: #F7F9F9 ;
border-radius: 0px;
}


.contact__email input,textarea {
    font-size: 18px;
    border-radius: 0px;
    height: 30px;
    background-color: #272727;
    outline:none;
    color:#363020 ;
    -webkit-text-fill-color: #F7F9F9 ;
}

.contact__message textarea {  
    border-radius: 0px;
     font-family: 'Manrope', sans-serif;
     font-size: 18px;
    background-color: #272727;
    width: 400px;
    height: 200px;
    outline:none;
    color:#363020 ;
    -webkit-text-fill-color: #F7F9F9 ;
}

.contact__button > button{
    display: flex;
    /* flex-direction: column; */
   justify-content: center;
   align-items: center;  
    font-family: 'Manrope', sans-serif;
    border-radius: 0%;
    background-color:  rgba(231,226,217, 0);
    width: 200px;
    height: 50px;
    margin: 10px;
    outline:none;
    margin-left: 130px;
    
}
.contact__button > button:hover{
   
}


/* 
.contact button:hover{
    background-color: blue ;
} */
body{
overflow-x: hidden;
}

.about {
overflow-x: hidden;
position: relative;
display: flex;
align-items: center;
justify-items: center;
margin-top: 350px;
margin-bottom: 80px;
/* box-shadow: inset -3px -3px 7px #ffffffb0, inset 3px 3px 5px rgba(94, 104, 121, 0.692); */
}

.about__me{
    position: relative;
    font-family: 'Poiret One', cursive;
    color:#363020;
}

.about__me.active {
    color:#fae588;
    opacity: 0.7;
}



.about__intro{
    /* border: 10px solid white; */
    /* box-shadow: -3px -3px 7px #ffffffb2, 
    3px 3px 5px rgba(94, 104, 121, 0.945);    */
     position: relative;
    margin-left: 4px;
    width: 60%;
    object-fit: cover;
    
}
.about__title{
    /* background-color: gray; */
    position: relative;
    /* box-shadow: 
    10px 10px 15px #363020,
    -10px -10px 15px  #363020; */
    /* box-shadow: $inner-shadow;
    color: var(--greyDark); */
    left: 5px;
    font-size: xx-large;

    bottom: 40px;
    width: 40%;
    object-fit: cover;
    /* height: 400px; */
}



.about__p{
    font-family: 'Palanquin', sans-serif;
    font-weight: 800px;
    display: flex !important;
    text-align: left;
    line-height: 2.5;
    color:#363020;
}

.about__p.active{
    color:#fae588;
    opacity: 0.7;
}
.about__skills img{
    height: 50px;
    margin: 10px;
    transition: transform 120ms ease-in;

}
.about__skills{
    /* display: flex;
align-items: center;
justify-items: center; */
position: relative;

opacity: 0.9; 
      /* box-shadow: 
    10px 10px 15px #363020,
    -10px -10px 15px  #363020; */
    border-radius: 5px;
    /* height: 100px; */
    width: 1400px;
    width: 90%;
    height: 40%;
    object-fit: cover;
}

.about__skills.active{
    background-color:#fdf8e1;
    opacity: 0.9; 
}


.about__skills img:hover{
    transform: scale(1.20)
}
.work{
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.work__1{
    display: flex;
    margin: 10px;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    ;

}


.work__header{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poiret One', cursive;
  font-size: 300%;
  margin: 0px;
  color:#fae588;
}
#work__code {
 display: flex;
 align-items: center;
 justify-content: center;
flex-direction: row;
}
#work__code > a{
    color: black;
    margin:5px;
    font-family: 'Poiret One', cursive !important;
    font-size: 3vh;


}
.work__project{
display: flex;
flex-direction: row;
align-items: center;
justify-items: center;
margin-left: 5px;
overflow-x: scroll;
overflow-y: hidden;
}
.work__project::-webkit-scrollbar{
    display: none;
}

.work__stack{
display: flex;
flex-direction: column;
background-color:hsl(49, 88%, 94%);
margin-left: 10px;
border-radius: 10px;
justify-content: center;
align-items: center;
width: 120vh;
height: 61vh;
object-fit: cover;

}

.work__stack img{
    
    height: 15%;
}


.work__project img{
height: 58vh;
width: 95vh;
border: 2px solid hsl(49, 88%, 94%);
margin: 5px;
}
.work__stack img{
}
body{
}
.resume__container{
    display: flex;
    justify-content: center;
   align-items: center; 
 margin: 50px
}

.resume__img  {
    border: 15px solid white;
    /* box-shadow: 0px 10px 18px -0px #363020; */
    object-fit: cover;
    width: 60%;
}
body{
    overflow-y: scroll;
    overflow-x:scroll
  
  }
  body::-webkit-scrollbar {
    display: none;
  }
  .Header {
    overflow-x:none;
    display: flex;
    flex-direction: column;
    text-align: center;
    color:#363020;
    overflow-y: hidden;
  }
  
  .Header__header{
    cursor: pointer;
    overflow-x:none;
    z-index: 999;
    font-family: 'Poppins', sans-serif;
    position: fixed;
    /* left:500px; */
    background-color: #202020;
    /* background-color: black; */
    bottom: 950px;
    margin-top: 0px;
    left: 0px;
    right:0px;
  top:0px;
  width:195vh;
  height: 20vh;
  /* box-shadow: 0px 6px 18px -0px #363020; */
  /* box-shadow: 0px 6px 18px -0px #363020; */

  }
  
  .Header__header h1{
  display: flex;
    font-family: 'Poiret One', cursive;
    font-size: 500%;
    margin: 0px;
    margin-right: 10vh;
    color:#fae588;
    opacity: 0.7;
    align-items: center;
    justify-content: center;
  }
  
  .Header__header p {
    color:#fae588;
    margin: 0px;
    margin-bottom: 10px;
  }
  
  .contact__component{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  /* 605,838 */
  
  .Header__header button{
    /* display: flex;
    flex-direction: row; */
    border-radius: 0%;
    background-color: #202020;
    /* background-color: white; */
    width: 50px;
    height: 50px;
    margin: 5px;
    margin-top: 0px;
    top: 0px;
    border: none;
    outline:none;
  }
  
  .buttons{
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    margin-left:1105px;
  }

@media(min-width: 100px){


/* 
  .Header__icons{
  display: flex;
  flex-direction: column;
  border: 2px solid white;
  } */











  .Header__header h1{
    display: flex;
      font-family: 'Poiret One', cursive;
      font-size: 500%;
      margin: 0px;
      margin-right: 10vh;
      color:#fae588;
      opacity: 0.7;
      align-items: center;
      justify-content: center;
    }

  .Header__header.active{
    overflow-x:none;
    z-index: 2;
    font-family: 'Poppins', sans-serif;
    position: fixed;
     box-shadow: 0px 6px 18px -0px #363020;
     background-color: #202020;
     /* border-radius: 10px; */
     bottom: 950px;
     margin-top: 0px;
     left: 0px;
     right:0px;
   top:0px;
   width:195vh;
   height: 26.5vh;
  }

  .links{
    position: relative !important;
    width: 3.3vh;
    height: 3vh;
  color:#fae588 !important;
  opacity: 0.7 !important; 
  bottom: 2.2vh;

  }
  
  .resume{
      position: relative !important;
  width: 40px !important;
  height: 33px !important;
  color:#fae588 !important;
  opacity: 0.7 !important; 
  bottom: 1.2vh;
  }
  .Header__header p {
    color:#fae588;
    margin: 0px;
    margin-bottom: 2vh;
  }
}



  @media (min-width: 1500px){

  .Header__header h1{
    display: flex;
      font-family: 'Poiret One', cursive;
      font-size: 500%;
      margin: 0px;
      color:#fae588;
      opacity: 0.7;
      align-items: center;
      justify-content: center;
    }
    .Header__header p {
      color:#fae588;
      margin: 0px;
      margin-bottom: 10px;
    }
    .Header__header.active{
      overflow-x:none;
      z-index: 2;
      font-family: 'Poppins', sans-serif;
      position: fixed;
       box-shadow: 0px 6px 18px -0px #363020;
       background-color: #202020;
       /* border-radius: 10px; */
       bottom: 950px;
       margin-top: 0px;
       left: 0px;
       right:0px;
     top:0px;
     width:195vh;
     height: 27vh;
  
    }
    .links{
      position: relative !important;
      width: 3.3vh;
      height: 3vh;
    color:#fae588 !important;
    opacity: 0.7 !important; 
    bottom: 1.5vh;
  
    }
    
    .resume{
        position: relative !important;
    width: 40px !important;
    height: 33px !important;
    color:#fae588 !important;
    opacity: 0.7 !important; 
    bottom: 1.2vh;
    }
  }
  
  .App__rest{
    margin-top: 250px;
  }
  .App__rest h1 {
    font-family: 'Poiret One', cursive;
  }
