@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Poppins:wght@200&display=swap');


body{
    overflow-y: scroll;
    overflow-x:scroll
  
  }
  body::-webkit-scrollbar {
    display: none;
  }
  .Header {
    overflow-x:none;
    display: flex;
    flex-direction: column;
    text-align: center;
    color:#363020;
    overflow-y: hidden;
  }
  
  .Header__header{
    cursor: pointer;
    overflow-x:none;
    z-index: 999;
    font-family: 'Poppins', sans-serif;
    position: fixed;
    /* left:500px; */
    background-color: #202020;
    /* background-color: black; */
    bottom: 950px;
    margin-top: 0px;
    left: 0px;
    right:0px;
  top:0px;
  width:195vh;
  height: 20vh;
  /* box-shadow: 0px 6px 18px -0px #363020; */
  /* box-shadow: 0px 6px 18px -0px #363020; */

  }
  
  .Header__header h1{
  display: flex;
    font-family: 'Poiret One', cursive;
    font-size: 500%;
    margin: 0px;
    margin-right: 10vh;
    color:#fae588;
    opacity: 0.7;
    align-items: center;
    justify-content: center;
  }
  
  .Header__header p {
    color:#fae588;
    margin: 0px;
    margin-bottom: 10px;
  }
  
  .contact__component{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  /* 605,838 */
  
  .Header__header button{
    /* display: flex;
    flex-direction: row; */
    border-radius: 0%;
    background-color: #202020;
    /* background-color: white; */
    width: 50px;
    height: 50px;
    margin: 5px;
    margin-top: 0px;
    top: 0px;
    border: none;
    outline:none;
  }
  
  .buttons{
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    margin-left:1105px;
  }

@media(min-width: 100px){


/* 
  .Header__icons{
  display: flex;
  flex-direction: column;
  border: 2px solid white;
  } */











  .Header__header h1{
    display: flex;
      font-family: 'Poiret One', cursive;
      font-size: 500%;
      margin: 0px;
      margin-right: 10vh;
      color:#fae588;
      opacity: 0.7;
      align-items: center;
      justify-content: center;
    }

  .Header__header.active{
    overflow-x:none;
    z-index: 2;
    font-family: 'Poppins', sans-serif;
    position: fixed;
     box-shadow: 0px 6px 18px -0px #363020;
     background-color: #202020;
     /* border-radius: 10px; */
     bottom: 950px;
     margin-top: 0px;
     left: 0px;
     right:0px;
   top:0px;
   width:195vh;
   height: 26.5vh;
  }

  .links{
    position: relative !important;
    width: 3.3vh;
    height: 3vh;
  color:#fae588 !important;
  opacity: 0.7 !important; 
  bottom: 2.2vh;

  }
  
  .resume{
      position: relative !important;
  width: 40px !important;
  height: 33px !important;
  color:#fae588 !important;
  opacity: 0.7 !important; 
  bottom: 1.2vh;
  }
  .Header__header p {
    color:#fae588;
    margin: 0px;
    margin-bottom: 2vh;
  }
}



  @media (min-width: 1500px){

  .Header__header h1{
    display: flex;
      font-family: 'Poiret One', cursive;
      font-size: 500%;
      margin: 0px;
      color:#fae588;
      opacity: 0.7;
      align-items: center;
      justify-content: center;
    }
    .Header__header p {
      color:#fae588;
      margin: 0px;
      margin-bottom: 10px;
    }
    .Header__header.active{
      overflow-x:none;
      z-index: 2;
      font-family: 'Poppins', sans-serif;
      position: fixed;
       box-shadow: 0px 6px 18px -0px #363020;
       background-color: #202020;
       /* border-radius: 10px; */
       bottom: 950px;
       margin-top: 0px;
       left: 0px;
       right:0px;
     top:0px;
     width:195vh;
     height: 27vh;
  
    }
    .links{
      position: relative !important;
      width: 3.3vh;
      height: 3vh;
    color:#fae588 !important;
    opacity: 0.7 !important; 
    bottom: 1.5vh;
  
    }
    
    .resume{
        position: relative !important;
    width: 40px !important;
    height: 33px !important;
    color:#fae588 !important;
    opacity: 0.7 !important; 
    bottom: 1.2vh;
    }
  }
  
  .App__rest{
    margin-top: 250px;
  }
  .App__rest h1 {
    font-family: 'Poiret One', cursive;
  }